import styled from 'styled-components';
import detailsIllustration from 'assets/illustrations/details.svg';

export const Wrapper = styled.div`
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
`;

export const SkillsWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 2rem 0;
  }
`;

export const Details = styled.div`
  flex: 1;
  padding-left: 2rem;
  align-self: baseline;

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 4em;
    color: #c7c7c7;

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }

  h2, a {
    color: ${({ theme }) => (theme === 'dark' ? '#c7c7c7' : '#707070')};
  }

  h2 {
    @media (max-width: 960px) {
      font-size: 1.5em;
    }
  }

  p {
    font-size: 12pt;
    font-weight: normal;
    line-height: 1.3;
    color: ${({ theme }) => (theme === 'dark' ? '#c7c7c7' : '#707070')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  align-self: flex-start;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
    max-height: 60vw;
    overflow: hidden;
  }

  img {
    width: 100%;
  }
`;

export const Signo = styled.div`
  margin-bottom: -2.5em;
  width: 14em;
  @media (max-width: 960px) {
    margin-bottom: -2em;
  }
` ;

export const SignoTitle = styled.div`
  margin-left: 2.5em;
  color: #c7c7c7;
  @media (max-width: 960px) {
    margin-left: 1.5em;
  }
` ;

export const Diploma = styled.div`
  margin-top: 2em;
`;
